<template>
  <b-form-group
    label="Vergi Dairesi"
    label-for="tax_office"
  >
    <b-form-input
      id="tax_office"
      v-model="dataItem.tax_office"
      placeholder="Vergi Dairesi"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'Notes',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
}
</script>
